<template>
    <div class="fondo-degradado-recover"></div>
    <div class="contenedor-recover d-flex align-items-center justify-content-center flex-column">
        <h1 class="titulo-recover">¡Verifica tu correo aquí!</h1>
        <h2 class="subtitulo-recover">Código.</h2>
        <FormCustom
            :objectForm="[
                {
                    type: 'codigo',
                    limitNumbers: 1,
                    arrayInputs: [
                        {
                            name: 'numero1',
                        },
                        {
                            name: 'numero2',
                        },
                        {
                            name: 'numero3',
                        },
                        {
                            name: 'numero4',
                        },                                             
                    ]
                }
            ]"
            :isOneButton="true"
            textSecButton="Recuperar"
            :actionSecButton="(data)=>confirmarCorreo(data)"
            :style="{
                paddingTop: '1em',
                height: '200px',
            }"
        >
            <template v-slot:contentBeforeButton>
                <div class="d-flex flex-row justify-content-center">
                    <router-link to="/app/profile" class="link-recover">Volver</router-link>
                </div>
            </template>
        </FormCustom>
        <br>
        <div class="error-login d-flex justify-content-center align-items-center" v-if="this.stateLogin.success === 'error'">
            <div><p>{{mensaje}}</p></div>
        </div>  
        <div class="correcto-login d-flex justify-content-center align-items-center" v-if="this.stateLogin.success === 'ok'">
            <div><p>{{mensaje}}</p></div>
        </div> 
    </div>
</template>

<script>
    import Footer from "../../components/Footer.vue"
    import {mapState, mapActions} from "vuex"
    import FormCustom from '../../components/FormCustom.vue'
    import {swift} from '../../helpers/swift'
    import {quitarError, quitarHecho} from '../../helpers/quitarAvisosForm'

    export default {
        name: "Confirm",
        components: {
            Footer,
            FormCustom
        },
        data(){
            return{
                //control de respuestas de la api
                mensaje:'',

                //formulario cambio password
                textoBoton:'Recuperar',
            }
        },
        methods:{
            ...mapActions(["confirmEmail", "clearStateLogin", "badRegisterFront"]),

            //confirmar correo del usuario

            async confirmarCorreo(data){
                let codigo = "" 
                Object.values(data).map(num => codigo += num)
                localStorage.setItem('codigo', codigo)
                await this.confirmEmail()

                //control de respuestas
                if(this.stateLogin.message === "Error en validación"){
                    this.$router.push({path: '/login'})
                }
                if(this.stateLogin.message === "Código no coincide"){
                    this.swift('El código no coincide', 1)
                    return
                }
                if(this.stateLogin.message === 'Usuario editado'){
                    this.swift('Correo confirmado', 2, '/app/profile')
                    return
                }else{
                    this.badRegisterFront()
                    this.swift('Error', 1)
                    return
                }
            },
            //funcion encargada de quitar la caja de error

            quitarError,

            //funcion encargada de quitar la caja de correcto

            quitarHecho,

            //swift entre error y correcto

            swift,
        },
        mounted(){
            this.$forceUpdate()
        },
        computed:{
        ...mapState(["token", "server", "stateLogin"]),
        }
    }
</script>

<style scoped>
    .error-login{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(128, 18, 22,0.75);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .correcto-login{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(11, 114, 40,0.75);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .link-recover{
        width: 150px;
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
        margin-top: 25px;
        color: #297F87;
        text-decoration: none;
    }
    .contenedor-recover{
        position: relative;
        height: 600px;
        width: 100%;
        overflow: hidden;
    }
    .fondo-degradado-recover{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%);
        height: 100vh;
    }
    .titulo-recover{
        font-family: Rubik;
        font-weight: bold;
        margin-top:80px ;
        font-size: 55px;
    }
    .subtitulo-recover{
        font-family: Rubik;
        margin-top:5px ;
        font-weight: bold;
        font-size: 25px;
    }
    .form-recover{
        width: 50%;
        margin-top: 45px;
    }
    .input,.input2{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 50px;
        height: 50px;
        font-size:40px ;
        text-align: center;
        padding-left: 3px;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .input2{
        margin-left:35px ;
    }
    .button-recover{
        width: 40%;
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        margin-top: 50px;
    }
</style>